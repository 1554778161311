import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import "./datenschutz.scss";

export default class Datenschutz extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Datenschutz" />
        <section id="dataprotection">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <h2>Online Datenschutzerklärung</h2>
                <br />
                <br />
                <h3>Unser Verständnis</h3>
                <h5>
                  Wer ist verantwortlich für die Datenerfassung auf dieser
                  Website?
          </h5>
                <p>
                  Die Datenverarbeitung auf dieser Website erfolgt durch den
                  Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum
                  dieser Website entnehmen.
          </p>
                <h5>Wie erfassen wir Ihre Daten?</h5>
                <p>
                  Ihre Daten werden zum einen dadurch erhoben, dass Sie uns
                  diese mitteilen. Hierbei kann es sich z.B. um Daten handeln,
                  die Sie in ein Kontaktformular eingeben.
          </p>
                <p>
                  Andere Daten werden automatisch beim Besuch der Website durch
                  unsere IT-Systeme erfasst. Das sind vor allem technische Daten
                  (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des
                  Seitenaufrufs). Die Erfassung dieser Daten erfolgt
                  automatisch, sobald Sie unsere Website betreten.
          </p>
                <h5>Wofür nutzen wir Ihre Daten?</h5>
                <p>
                  Ein Teil der Daten wird erhoben, um eine fehlerfreie
                  Bereitstellung der Website zu gewährleisten. Andere Daten
                  können zur Analyse Ihres Nutzerverhaltens verwendet werden.
          </p>
                <h5>Welche Rechte haben Sie bezüglich Ihrer Daten?</h5>
                <p>
                  Sie haben jederzeit das Recht unentgeltlich Auskunft über
                  Herkunft, Empfänger und Zweck Ihrer gespeicherten
                  personenbezogenen Daten zu erhalten. Sie haben außerdem ein
                  Recht, die Berichtigung, Sperrung oder Löschung dieser Daten
                  zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema
                  Datenschutz können Sie sich jederzeit unter der im Impressum
                  angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen
                  ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          </p>
                <h5>Einverständnis</h5>
                <p>
                  Durch die Nutzung unserer Website erklären Sie sich mit der
                  Erhebung, Verarbeitung und Nutzung von Daten gemäß der
                  nachfolgenden Beschreibung einverstanden. Unsere Website kann
                  grundsätzlich ohne Registrierung besucht werden. Dabei werden
                  Daten wie beispielsweise aufgerufene Seiten bzw. Namen der
                  abgerufenen Datei, Datum und Uhrzeit zu statistischen Zwecken
                  auf dem Server gespeichert, ohne dass diese Daten unmittelbar
                  auf Ihre Person bezogen werden. Personenbezogene Daten,
                  insbesondere Name, Adresse oder E-Mail-Adresse werden soweit
                  möglich auf freiwilliger Basis erhoben. Ohne Ihre Einwilligung
                  erfolgt keine Weitergabe der Daten an Dritte.
          </p>
                <h5>Analyse-Tools und Tools von Drittanbietern</h5>
                <p>
                  Beim Besuch unserer Website kann Ihr Surf-Verhalten
                  statistisch ausgewertet werden. Das geschieht vor allem mit
                  Cookies und mit sogenannten Analyseprogrammen. Die Analyse
                  Ihres Surf-Verhaltens erfolgt in der Regel anonym; das
                  Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Sie
                  können dieser Analyse widersprechen oder sie durch die
                  Nichtbenutzung bestimmter Tools verhindern. Detaillierte
                  Informationen dazu finden Sie in der folgenden
            Datenschutzerklärung.{" "}
                </p>
                <p>
                  Sie können dieser Analyse widersprechen. Über die
                  Widerspruchsmöglichkeiten werden wir Sie in dieser
                  Datenschutzerklärung informieren.
          </p>
                <h3>Unsere Leitlinien zum Schutz Ihrer Daten</h3>
                <p>
                  Der Schutz Ihrer Privatsphäre bei der Verarbeitung
                  persönlicher Daten sowie die Sicherheit aller Geschäftsdaten
                  ist uns ein sehr wichtiges Anliegen, das wir in unseren
                  Prozessen berücksichtigen und genießt bei uns höchste
                  Priorität. Wir behandeln Ihre persönlichen Daten, die bei
                  Ihrem Besuch unserer Webseiten erhoben werden, vertraulich und
                  in Übereinstimmung mit den bestehenden Datenschutznormen.
                  Gesetzliche Änderungen und die fortlaufende Entwicklung machen
                  gelegentliche Anpassungen unserer Datenschutzerklärung
                  erforderlich. Wir behalten uns das Recht vor, jederzeit
            entsprechende Änderungen vorzunehmen.{" "}
                </p>
                <h3>Hinweis auf die verantwortliche Stelle</h3>
                <p>
                  Die Einhaltung der Datenschutzvorschriften wird von unserer
                  Geschäftsführung in Ihrem Interesse überwacht. Für eine
                  weitergehende Auskunft, Anregungen oder Beschwerden bzgl. der
                  Verarbeitung Ihrer personenbezogener Daten wenden Sie sich
                  bitte unmittelbar an uns.
          </p>
                <p>E-Mail:</p>
                <a href="mailto:info@ruoss-bau.de">
                  info@ruoss-bau.de
          </a>
                <br />
                <p>
                  Alexander Ruoß Bauunternehmen
            <br /> Forchenweg 12<br /> 72290 Loßburg
          </p>

                <p>
                  <br /> Geschäftsführung: Alexander Ruoß
          </p>
                <h3>Erhebung und Verarbeitung persönlicher Daten</h3>
                <h5>Server-Log Daten</h5>
                <p>
                  Zur Administration, zur optimalen Gestaltung und zur Sicherung
                  unserer Internetseiten speichern wir bei beim Aufruf unserer
                  Seiten:
          </p>
                <ul>
                  <li>Ihre IP-Adresse, </li>
                  <li>Browsertyp und Browserversion</li>
                  <li>Verwendetes Betriebssystem</li>
                  <li>Referrer URL</li>
                  <li>Hostname des zugreifenden Rechners</li>
                  <li>sowie Datum und Verweildauer</li>
                </ul>
                <p>
                  Eine Zusammenführung dieser Daten mit anderen Datenquellen
                  wird nicht vorgenommen, soweit dies nicht zur Verfolgung oder
                  Aufdeckung einer Straftat erforderlich ist. Grundlage für die
                  Datenverarbeitung ist Art. 6 Abs. 1 lit. B DSGVO, der die
                  Verarbeitung von Daten zur Erfüllung eines Vertrages oder
                  vorvertragliche Maßnahmen erstattet. codeunity behält sich vor
                  diese Daten zur Verfolgung oder Aufdeckung einer Straftat an
                  die zuständigen Strafverfolgungsbehörden weiterzugeben.
                  Weitere personenbezogene Daten wie Name, Adresse, Telefon Nr.
                  und E-Mail-Adresse werden nur dann gespeichert, wenn Sie uns
                  diese von sich aus, z.B. im Rahmen einer Kontaktaufnahme oder
                  zur Durchführung eines Vertrages angeben.
          </p>
                <h5>Verarbeiten von Daten (Kunden- und Vertragsdaten)</h5>
                <p>
                  Wir erheben, verarbeiten und nutzen personenbezogene Daten
                  nur, soweit sie für die Begründung, inhaltliche Ausgestaltung
                  oder Änderung des Rechtsverhältnisses erforderlich sind
                  (Bestandsdaten). Dies erfolgt auf Grundlage von Art. 6 Abs. 1
                  lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung
                  eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
                  Personenbezogene Daten über die Inanspruchnahme unserer
                  Internetseiten (Nutzungsdaten) erheben, verarbeiten und nutzen
                  wir nur, soweit dies erforderlich ist, um dem Nutzer die
                  Inanspruchnahme des Dienstes zu ermöglichen oder abzurechnen.
          </p>
                <p>
                  Die erhobenen Kundendaten werden nach Abschluss des Auftrags
                  oder Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche
                  Aufbewahrungsfristen bleiben unberührt.
          </p>
                <h5>E-Mail</h5>
                <p>
                  Wenn Sie uns eine E-Mail senden, werden diese Daten
                  gespeichert. Weitere personenbezogene Daten wie Name, Adresse,
                  Telefon Nr. und E-Mail-Adresse werden nur dann gespeichert,
                  wenn Sie uns diese von sich aus, z.B. im Rahmen einer
                  Kontaktaufnahme oder zur Durchführung eines Vertrages angeben.
                  Wir tragen Sorge dafür, dass die Daten nach dem Stand der
                  Technik vor der unberechtigten Kenntnisnahme Dritter gesichert
                  sind. Beachten Sie jedoch bitte, dass unverschlüsselte
                  E-Mails, die über das Internet versendet werden, nicht
                  hinreichend vor der unbefugten Kenntnisnahme durch Dritte
            geschützt sind.{" "}
                </p>
                <h5>Cookies</h5>
                <p>
                  Unsere Website verwendet Cookies. Das sind kleine Textdateien,
                  die es möglich machen, auf dem Endgerät des Nutzers
                  spezifische, auf den Nutzer bezogene Informationen zu
                  speichern, während er die Website nutzt. Cookies ermöglichen
                  es, insbesondere Nutzungshäufigkeit und Nutzeranzahl der
                  Seiten zu ermitteln, Verhaltensweisen der Seitennutzung zu
                  analysieren, aber auch unser Angebot kundenfreundlicher zu
                  gestalten. Cookies bleiben über das Ende einer Browser-Sitzung
                  gespeichert und können bei einem erneuten Seitenbesuch wieder
                  aufgerufen werden. Sie können Ihren Browser so einstellen,
                  dass Sie über das Setzen von Cookies informiert werden und
                  einzeln über deren Annahme entscheiden oder die Annahme von
                  Cookies für bestimmte Fälle oder generell ausschließen.
                  Näheres hierzu können Sie der Beschreibung Ihres Browser
                  entnehmen. Wir möchten Sie jedoch darauf hinweisen, dass ohne
                  die Cookies eine volle Funktionalität der Webseite nicht
            gewährleistet werden kann.{" "}
                </p>
                <h3>Analyse Tools</h3>
                <p>
                  Die Website verwendet u.a. Google Analytics, einen
                  Webanalysedienst von Google Inc., 1600 Amphitheatre Parkway,
                  Mountain View, CA 94043, USA. Diese ermöglichen eine Analyse
                  der Nutzung unseres Websiteangebotes durch Google. Die durch
                  den Cookie erfassten Informationen über die Nutzung unserer
                  Seiten (einschließlich Ihrer IP-Adresse) werden in der Regel
                  an einen Server von Google in den USA übertragen und dort
                  gespeichert.
          </p>
                <p>
                  Wir haben auf dieser Website die Funktion IP-Anonymisierung
                  aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb
                  von Mitgliedstaaten der Europäischen Union oder in anderen
                  Vertragsstaaten des Abkommens über den Europäischen
                  Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur
                  in Ausnahmefällen wird die volle IP-Adresse an einen Server
                  von Google in den USA übertragen und dort gekürzt. Im Auftrag
                  des Betreibers dieser Website wird Google diese Informationen
                  benutzen, um Ihre Nutzung der Website auszuwerten, um Reports
                  über die Websiteaktivitäten zusammenzustellen und um weitere
                  mit der Websitenutzung und der Internetnutzung verbundene
                  Dienstleistungen gegenüber dem Websitebetreiber zu erbringen.
                  Die im Rahmen von Google Analytics von Ihrem Browser
                  übermittelte IP-Adresse wird nicht mit anderen Daten von
                  Google zusammengeführt.
          </p>
                <p>
                  Die Speicherung von Google-Analytics-Cookies erfolgt auf
                  Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber
                  hat ein berechtigtes Interesse an der Analyse des
                  Nutzerverhaltens, um sowohl sein Webangebot als auch seine
                  Werbung zu optimieren.
          </p>
                <p>
                  Mehr erfahren Sie unter
            <a href="http://www.google.com/intl/de/analytics/privacyoverview.html">
                    {" "}
                    http://www.google.com/intl/de/analytics/privacyoverview.html
            </a>
                  .{" "}
                </p>
                <h3>Widerspruchsrecht i.S.d. § 15 Abs. 3 TMG</h3>
                <p>
                  Sie können die Analyse des Nutzerverhaltens unterbinden, indem
                  Sie ihrem Browser das Setzen von Cookies durch diese Domain
                  blockieren, indem Sie z.B. eine Ausnahme im Browser
                  einrichten. Bitte sehen Sie ggf. in der Programmhilfe nach,
                  wie das in Ihrem Browser eingerichtet werden kann. Wenn Sie in
                  dem Browser die sog. Do-Not-Track-Funktion („DNT“) aktiviert
                  haben, dann wird Ihr Besuch auf den Internetseiten automatisch
                  nicht vom Webanalyse-Tool erfasst. Allerdings funktioniert das
                  nicht bei jedem Browser. Um DNT in Ihrem Browser zu
                  aktivieren, schauen Siebitte in der Hilfe-Funktion des
                  Browsers nach. Sie können die Speicherung der Cookies durch
                  eine entsprechende Einstellung Ihrer Browser-Software
                  verhindern; wir weisen Sie jedoch darauf hin, dass Sie in
                  diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser
            Website vollumfänglich werden nutzen können.{" "}
                </p>
                <p>
                  Mehr Informationen zum Umgang mit Nutzerdaten bei Google
                  Analytics finden Sie in der Datenschutzerklärung von Google:
            <a href="https://support.google.com/analytics/answer/6004245?hl=de">
                    https://support.google.com/analytics/answer/6004245?hl=de
            </a>
                </p>
                <p>
                  Viele Datenverarbeitungsvorgänge sind nur mit ihrer
                  ausdrücklichen Einwilligung möglich. Sie können eine bereits
                  erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine
                  formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der
                  bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
                  Widerruf unberührt.
          </p>
                <h3>Auskunfts- und Beschwerderecht</h3>
                <h5>Auskunftsrecht</h5>
                <p>
                  Auf Anforderung teilen wir Ihnen schriftlich entsprechend dem
                  jeweils geltenden Recht mit, ob und welche personenbezogenen
                  Daten über Sie bei uns gespeichert sind. Sie haben außerdem
                  ein Recht, die Berichtigung, Sperrung oder Löschung dieser
                  Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema
                  Datenschutz können Sie sich jederzeit unter der angegebenen
                  Adresse an uns wenden. Des weiteren steht Ihnen ein
            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.{" "}
                </p>
                <h5>Beschwerderecht</h5>
                <p>
                  Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen
                  ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                  Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen
                  ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem
                  unser Unternehmen seinen Sitz hat. Eine Liste der
                  Datenschutzbeauftragten sowie deren Kontaktdaten können
                  folgendem Link entnommen werden:
            <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
                    https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
            </a>
                </p>
                <h3>Server</h3>
                <p>
                  Der Server, auf dem die Internetseite gehosted wird, befindet
                  sich in einem Rechenzentrum in der Europäischen Union, genau
                  genommen in Frankfurt am Main oder Offenbach am Main. Das
                  Rechenzentrum wird von einem deutschen Unternehmen betrieben.
                  Mit dem Hosting-Provider haben wir einen
                  Auftragsdatenverarbeitungsvertrag geschlossen, der den
            Anforderungen des § 11 BDSG entspricht.{" "}
                </p>
                <h3>Links zu anderen Webseiten</h3>
                <p>
                  Eventuell vorhandene Links zu anderen Webseiten wurden nach
                  bestem Wissen und Gewissen ausgewählt. Auf deren Inhalt und
                  die Einhaltung datenschutzrechtlicher Vorgaben haben wir
                  jedoch keinen Einfluss. Die verlinkten Seiten wurden zum
                  Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft
                  und waren nicht erkennbar. Eine permanente inhaltliche
                  Kontrolle der verlinkten Inhalte ohne konkrete Anhaltspunkt zu
                  einem Rechtsverstoß erfolgt derzeit nicht. Bei Bekanntwerden
                  von Rechtsverstößen werden wir derartige Links umgehend
                  entfernen.
          </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}